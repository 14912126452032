






import { defineComponent } from "@vue/composition-api";
import MyStudents from "@/components/seller/MyStudents.vue";

export default defineComponent({
  components: { MyStudents },
  name: "SellerDashboard",
});
